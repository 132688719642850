import React from "react";
import { Chatbot, createChatBotMessage } from "react-chatbot-kit";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Config from "./Config";
import "./app.css";
import Data from "./data.json";
import ChatBotMessage from "./ChatBotMessage";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [message, setMessages] = React.useState("");
  const [botMessages, setBotMessages] = useState([
    {
      message: `<p><span style="font-size:14px">Hi, I'm ChatBot. How can I help you today?</span>
      </p>  
      `,
      sender: "bot",
      loading: false,
      links: [],
      isWelcomeMessage: true,
    },
  ]);

  const handleUserInput = async () => {
    // check if message is empty
    if (message === "") return;
    let msgs = [...botMessages];
    let newMessage = [
      {
        message: message,
        sender: "user",
        loading: false,
        links: [],
      },
      {
        message: "جاري التحميل ...",
        sender: "bot",
        loading: true,
        links: [],
      },
    ];
    setBotMessages([...botMessages, ...newMessage]);
    msgs = [...botMessages, ...newMessage];
    setMessages("");

    const response = await fetch("https://www.chatbase.co/api/v1/chat", {
      method: "POST",
      headers: {
        Authorization: "Bearer cab63279-45d6-414e-a466-827ceb0367f3",
      },
      body: JSON.stringify({
        messages: [{ content: message, role: "user" }],
        chatbotId: "Crft7EB_M7SkjplAotU9y",
        stream: false,
        temperature: 0,
        model: "gpt-3.5-turbo",
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw Error(errorData.message);
    }

    const data = JSON.parse(await response.text());

    if (!data) {
      // error happened
    }

    // get message from botMessages if it's loading
    let loadingMessage = msgs.find((msg) => msg.loading === true);
    if (!loadingMessage) return;
    loadingMessage.loading = false;
    loadingMessage.message = data.text;

    // get all links {url:,type:"pdf/doc/mp4..."} form any type
    //data,text ex: "text": "مرحبًا! يمكنك الاستماع إلى صوت حرف (م) بالمد الطويل من خلال الفيديو التالي: <a href=\"https://apis.ircascms.com/public/1692623844819_.mp4\" rel=\"noopener noreferrer\" target=\"_blank\">فيديو الصوت الطويل لحرف الميم</a>. استمتع بالاستماع! هل يمكنني مساعدتك بشيء آخر؟"

    const links = data.text.match(
      /https:\/\/apis.ircascms.com\/public\/[0-9]+_.[a-z0-9]+/gi
    );
    if (links) {
      loadingMessage.links = links.map((link) => ({
        type: link.split(".").pop(),
        link: link,
      }));
    }
    // set loadingMessage to botMessages
    setBotMessages(msgs);
    // scroll to bottom chatbotContainer
    const chatbotContainer = document.querySelector(".chatbotContainer");
    chatbotContainer.scrollTop = chatbotContainer.scrollHeight;
  };

  useEffect(() => {
    // scroll to bottom chatbotContainer
    const chatbotContainer = document.querySelector(".chatbotContainer");
    chatbotContainer.scrollTop = chatbotContainer.scrollHeight;
  }, []);

  useEffect(() => {
    const handleHistoryChange = () => {
      // Your code to run when window.history changes
      const url = window.location.href;
      const msg = url.split("#msg=")[1];
      if (msg) { 
        let msgTemp = msg;
        //convert msg to unicode utf-8
        msgTemp = decodeURIComponent(msgTemp);
        //set msg to input
        setMessages(msgTemp);
        //sendBtn click
        setTimeout(() => {
          const sendBtn = document.getElementById("sendBtn");
          sendBtn.click();
        }, 500);
        //remove #msg from url
        window.history.pushState("", "", "/");
      }
    };

    // Add event listener for popstate
    window.addEventListener("popstate", handleHistoryChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleHistoryChange);
    };
  }, []); // Empty dependency array ensures that the effect runs only once, similar to componentDidMount

  return (
    <>
      <div className="app">
        <div className="app__header">
          <div className="logo">
           
          </div>
          <div>Mubarak Chatbot</div>
        </div>
        <div className="App mx-auto col-md-6 col-sm-8 col-lg-4 cpl-xl-3 my-5">
          <div className="chatbotContainer">
            {botMessages.map((message) => (
              <ChatBotMessage message={message} />
            ))}
          </div>
          <div
            className={
              botMessages.find((msg) => msg.loading === true)
                ? "react-chatbot-kit-chat-input-container loadingBorder"
                : "react-chatbot-kit-chat-input-container"
            }
          >
            <input
              className="react-chatbot-kit-chat-input"
              placeholder="Type a message..."
              onChange={(e) => setMessages(e.target.value)}
              value={message}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleUserInput();
                }
              }}
              autoFocus
            />
            <button
              className="react-chatbot-kit-chat-btn-send"
              onClick={handleUserInput}
              id="sendBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="react-chatbot-kit-chat-btn-send-icon"
              >
                <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
